import styled from 'styled-components';

export const Hero = styled.div`
  background-image: ${({ ourStoryPage }) =>
    ourStoryPage
      ? "url('/static/images/homepage/our-story-hero.png') !important"
      : "url('/static/images/homepage/hero/hero-xl.png')"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: ${({ ourStoryPage }) => ourStoryPage ? '600px' : '800px'};
  padding-top: 84px;

  @media (max-width: 1440px) {
    background-image: url('/static/images/homepage/hero/hero-lg.png');
  }

  @media (max-width: 1024px) {
    background-image: url('/static/images/homepage/hero/hero-md.png');
    background-position: center;
  }

  @media (max-width: 680px) {
    background-image: url('/static/images/homepage/hero/hero-sm.png');
    height: auto;
  }
`;

export const HeroBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 32px;

  @media (max-width: 1024px) {
    margin-top: 24px;
  }

  @media (max-width: 680px) {
    margin-top: 16px;
  }
`;

export const HeroButton = styled.button`
  margin-top: 32px;
  padding: 16px 24px;
  border: ${({ showBorder }) => (showBorder ? '2px solid #FFFFFF' : 'none')};
  border-radius: 58px;
  background: transparent;
  display: flex;
  align-items: center;

  span {
    ${({ margin }) => margin && 'margin-left: 10px;'}
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 110%;
    color: #ffffff;
  }

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    transform: none !important;
    margin: 0 !important;
    width: 24px !important;
    height: 24px !important;
  }

  @media (max-width: 1024px) {
    padding: 12px 22px;
    span {
      font-size: 16px;
    }
  }

  @media (max-width: 680px) {
    margin-top: 24px;
    width: 100%;
    justify-content: center;
    padding: 8px 16px;
    span {
      font-size: 13px;
    }

    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
`;

export const HeroTextLayout = styled.div`
  width: 1072px;
  display: flex;
  flex-direction: row;
  margin: 84px auto;

  @media (max-width: 1095px) {
    padding: 45px;
    width: 100%;
    margin: 0;
    img {
      margin-left: -14px;
      margin-top: 90px;
    }
  }

  @media (max-width: 680px) {
    padding: 40px 24px 64px;
    flex-direction: column;
    img {
      margin-top: 24px;
      width: 170px;
      height: 343px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  width: 67%;

  h1 {
    font-family: 'ivypresto-display', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 110%;
  }

  h2 {
    font-family: 'Satoshi', sans-serif;
    margin-top: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;

    width: 650px;

    span {
      font-family: 'Satoshi', sans-serif;
      font-weight: 500;
    }
  }

  @media (max-width: 1024px) {
    width: 80%;
    h1 {
      font-size: 56px;
    }

    h2 {
      font-size: 18px;
      width: fit-content;
    }
  }

  @media (max-width: 680px) {
    width: 100%;
    h1 {
      font-size: 32px;
      width: 282px;
    }

    h2 {
      font-size: 15px;
      line-height: 120%;

      margin-top: 16px;
      margin-right: 0px;
    }
  }
`;
