import {
  Button,
  Buttonlayout,
  FAQCarrot,
  FAQsAnswer,
  FAQsButton,
  FAQsButtonContainer,
  FAQsHead,
  FAQsItem,
  FAQsLayout,
  FAQsOutter,
  FAQsOutterWrap,
  FAQsQuestion,
  FAQsText,
  FAQsWrapper,
  GetStartedLayout,
  MainContainer,
  OutterLayout,
  StartedDescript,
  StartHeading,
  Startlayout,
  Tick,
  TicksLayout,
  ScheduleButton,
} from './index.styles';
import TickIcon from '../../../assets/images/homepage/tick.svg';
import UpIcon from '../../../assets/images/homepage/carrot-up.svg';
import DownIcon from '../../../assets/images/homepage/carrot-down.svg';
import { useState } from 'react';
import { useRouter } from 'next/router';

const demoUrls = [
  '/product-demo',
  '/product-demo-videos',
];

const FAQs = ({ width, commonQuestions, showGetStarted = true }) => {
  const [questionOpen, setQuestionOpen] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });
  const router = useRouter();

  const watchADemo = () => {

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'demo_intent');
    }
    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'Lead');
    }

    if (demoUrls.includes(router.pathname)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      router.push('/product-demo');
    }
  }

  return (
    <MainContainer id="faq" ref={commonQuestions} showGetStarted={showGetStarted}>
      {showGetStarted && (
      <OutterLayout>
        <GetStartedLayout>
          <Startlayout>
            <StartHeading>
              {width <= 680 ? 'Get Started' : 'Get Started Today'}
            </StartHeading>
            <StartedDescript>
              Setup can be done in minutes and we provide resources and support
              to make onboarding as smooth as possible.
            </StartedDescript>
            <TicksLayout>
              <Tick>
                <TickIcon />
                <p>Cancel anytime</p>
              </Tick>
              <Tick>
                <TickIcon />
                <p>Easy setup</p>
              </Tick>
            </TicksLayout>
          </Startlayout>
          <Buttonlayout>
            <Button
              onClick={() => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'view_pricing');
                }
                router.push(
                  '/pricing' + (router.query.r ? `?r=${router.query.r}` : '')
                );
              }}
            >
              Get Started for Free
            </Button>
            <ScheduleButton
              black
              onClick={() => {
                if (location.pathname == '/demo') {
                  window.scrollTo(0, 0);
                } else {
                  router.push('/demo');
                }
              }}
            >
              Schedule a Demo
            </ScheduleButton>
          </Buttonlayout>
        </GetStartedLayout>
      </OutterLayout>
      )}
      <FAQsOutter>
        <FAQsLayout showGetStarted={showGetStarted}>
          <FAQsHead>Common Questions</FAQsHead>
          <FAQsText>
            We want you to have the best experience. If you have a specific
            question,{' '}
            <a href="mailto: support@gettogather.co">
              <u>send us an email</u>
            </a>{' '}
            and we’ll be in touch shortly.
          </FAQsText>
          <FAQsOutterWrap>
            <FAQsWrapper>
              <FAQsItem>
                <FAQsQuestion
                  onClick={() =>
                    setQuestionOpen({
                      ...questionOpen,
                      first: !questionOpen.first,
                    })
                  }
                >
                  <p>How does Togather compare to other church apps?</p>
                  <FAQCarrot>
                    {questionOpen.first ? <UpIcon /> : <DownIcon />}
                  </FAQCarrot>
                </FAQsQuestion>
                {questionOpen.first && (
                  <FAQsAnswer>
                    Togather is the only church tech platform built specifically
                    with small group engagement and management in mind. We know
                    that small groups are the heartbeat of a thriving church
                    community, so we’re building to make that social and
                    individual experience engaging, fun, helpful, and connected
                    to your church staff and pastoral team – all while making
                    sure you have the real-time data and connectivity tools you
                    need to manage community and events well. We are not just a
                    database tool.
                  </FAQsAnswer>
                )}
              </FAQsItem>
              <FAQsItem>
                <FAQsQuestion
                  onClick={() =>
                    setQuestionOpen({
                      ...questionOpen,
                      second: !questionOpen.second,
                    })
                  }
                >
                  <p>Do you have an Android app?</p>
                  <FAQCarrot>
                    {questionOpen.second ? <UpIcon /> : <DownIcon />}
                  </FAQCarrot>
                </FAQsQuestion>
                {questionOpen.second && (
                  <FAQsAnswer>
                    We do not. However, we do have a mobile web version of the
                    app that has everything the iPhone app offers. We will be
                    introducing our Android app in 2023.
                  </FAQsAnswer>
                )}
              </FAQsItem>
            </FAQsWrapper>
            <FAQsWrapper>
              <FAQsItem>
                <FAQsQuestion
                  onClick={() =>
                    setQuestionOpen({
                      ...questionOpen,
                      third: !questionOpen.third,
                    })
                  }
                >
                  <p>
                    Do you have any materials I can use to explain Togather to
                    my church?
                  </p>
                  <FAQCarrot>
                    {questionOpen.third ? <UpIcon /> : <DownIcon />}
                  </FAQCarrot>
                </FAQsQuestion>
                {questionOpen.third && (
                  <FAQsAnswer>
                    Absolutely! We have a welcome kit available to all churches
                    that includes a step-by-step playbook on sharing your new
                    app with your church, as well as materials you can share
                    during service or on social media that shares the different
                    ways your members can use your new app.
                  </FAQsAnswer>
                )}
              </FAQsItem>
              <FAQsItem>
                <FAQsQuestion
                  onClick={() =>
                    setQuestionOpen({
                      ...questionOpen,
                      fourth: !questionOpen.fourth,
                    })
                  }
                >
                  <p>Does this replace my ChMS?</p>
                  <FAQCarrot>
                    {questionOpen.fourth ? <UpIcon /> : <DownIcon />}
                  </FAQCarrot>
                </FAQsQuestion>
                {questionOpen.fourth && (
                  <FAQsAnswer>
                    Potentially. Togather doesn’t have every feature on every
                    ChMS available, but our ChMS works great for managing
                    members and their notes, group details, and more!
                  </FAQsAnswer>
                )}
              </FAQsItem>
            </FAQsWrapper>
          </FAQsOutterWrap>
          <FAQsButtonContainer>
            <FAQsButton>
              <a
                target="_blank"
                href="https://support.gettogather.co"
                rel="noopener noreferrer"
              >
                View All FAQs
              </a>
            </FAQsButton>
            <FAQsButton
              onClick={() => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'view_pricing');
                }
                router.push(
                  '/pricing' + (router.query.r ? `?r=${router.query.r}` : '')
                );
              }}
            >
              Get Started Risk Free
            </FAQsButton>
            <FAQsButton
              onClick={(e) => {
                if (location.pathname == '/demo') {
                  window.scrollTo(0, 0);
                } else {
                  router.push('/demo');
                }
              }}
            >
              Book a Demo
            </FAQsButton>
          </FAQsButtonContainer>
        </FAQsLayout>
      </FAQsOutter>
    </MainContainer>
  );
};

export default FAQs;
